/* MENU */
.top_nav,
.main_nav {
	width: 83.33%;
	float: right;
	text-align: right;

	li {
		display: inline-block;
		a {
			font-size: 0.938em;
			line-height: 1;
		}
	}
}

.top_nav {
	ul {
		float: right;
		margin-right: 15px;
	}
	li {
		padding: 17px 10px;
		a.current {
			color: $blue;
		}
	}
	.social_medias {
		font-size: 1em;
		i {
			color: $grey_light;
			@include transition();
			&:hover {
				color: $blue;
			}
		}
	}
}

.main_nav {
	li {
		padding: 30px 15px;
		a {
			font-weight: 700;
			text-transform: uppercase;

			&.active,
			&.current {
				color: $blue;
			}
		}
	}
	.submenu {
		position: absolute;
		width: 100%;
		left: 0;
		padding: 30px 0 20px;
		background-color: #fff;
		height: 0;
		@include transition();
		li {
			padding: 0 10px;
			width: 16.66%;
			float: left;
			img {
				width: 100%;
			}
			span {
				display: block;
				text-align: left;
				margin-top: 10px;
			}
		}
		&.innov_submenu {
			text-align: center;
			li {
				float: none;
				white-space: nowrap;
			}
		}
		.dropdown & {
			height: 195px;
		}
	}
}

.mobile_nav {
	background-color: $grey_bg1;
	width: 100%;
	height: auto;
	min-height: 100%;
	position: absolute;
	z-index: 9;
	top: 0;
	.content {
		overflow: scroll;
	    height: 100%;
	    position: absolute;
	}
	ul {
		padding-top: 55px;
		text-align: center;
	}
	li {
		display: block;
		padding: 13px 5px;
		border-bottom: 1px solid $border;

		&:last-of-type {
			border-bottom: 0;
		}
		&.tablet {
			display: none;
		}

		a {
			font-size: 1rem;
			line-height: 1;
			font-weight: 700;
			text-transform: uppercase;
			&.active,
			&.current {
				color: $blue;
			}
			&.current + .submenu {
				display: block !important;
				height: auto;
			}
		}
	}
	.submenu {
		padding-top: 10px;
		height: 0;
		overflow: hidden;
		@include transition(height 0.3s ease);
		li {
			border-bottom: 0;
			padding: 7px 5px;
		}
		a {
			font-size: 0.813rem;
		}
	}
	.dropdown & {
		.orthoSubmenu {
			height: 190px;
		}
		.innovSubmenu {
			height: 100px;
		}
	}
	.nav_social_medias {
		padding: 30px 0;
		li {
			display: inline-block;
			border-bottom: 0;
			padding: 0 10px;
		}
	}
	.rdv_btn {
		display: none;
		float: none;
		width: 280px;
		margin: 0 auto;
		text-align: center;
		padding: 14px 10px 17px;
	}
}
.close_menu_btn {
	height: 25px;
	width: 25px;
	position: absolute;
	right: 20px;
	top: 20px;

	span:before,
	span:after {
		position: absolute;
		height: 3px;
		width: 25px;
		top: 10px;
		left: 0;
		background-color: $grey_font;
	}

	span:before {
		content: '';
		@include transform(rotate(45deg));
	}
	span:after {
		content: '';
		@include transform(rotate(-45deg));
	}
}


/* BTN */
.btn {
	color: $blue;
	font-size: 0.938em;
	font-weight: 700;
	border: 2px solid $blue;
	text-transform: uppercase;
	text-align: center;
	padding: 15px;
	width: 280px;
	display: block;
	margin: 0 auto;

	&:hover {
		background: $blue;
		color: #fff;
	}

	&.big {
		width: 360px;
		padding: 20px;
		font-size: 1.125rem;
	}

	.home & {
		margin-top: 40px;
	}
}

.rdv_btn {
	float: right;
	background-color: $blue;
	padding: 14px 25px 17px;
	color: #fff;
	font-size: 0.938em;
	font-weight: 700;
	text-transform: uppercase;
	&:before {
		content: '';
		width: 16px;
		height: 18px;
		background: url('../images/calendar.png') no-repeat;
		display: inline-block;
		padding-right: 10px;
		position: relative;
		top: 2px;
	}
	&:hover {
		background-color: $blue_light;
		color: #fff;
	}
}
a.see_all {
	display: none;
	font-size: 0.875rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	padding-left: 30px;
}


/* NAV / PAGER */
.nav_slider {
	position: absolute;
	width: 100%;
	top: calc(50% - 36px);
	padding: 0 20px;
	.prev {
		float: left;
	}
	.next {
		float: right;
		i {
			display: block;
			@include transform(scale(-1));
		}
	}
	.prev,
	.next {
		i {
			font-size: 73px;
			color: $blue;
		}
	}
}
.nav_surgery_type {
	background-color: $blue_dark;
	.col {
		padding: 10px 0;
	}
	.nav_title {
		background-color: $blue;
		position: relative;
		height: 120px;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-align-items: center;
		align-items: center;
		&:before {
			content: '';
		    position: absolute;
		    background-color: #1972c5;
		    height: 100%;
		    width: 1000px;
		    top: 0;
		    z-index: 0;
		    left: -850px;
		}
		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 60px 0 60px 20px;
			border-color: transparent transparent transparent #1972c5;
			position: absolute;
			top: 0;
			right: -20px;
		}
		// add new class to nav 2018-03-28
		.dislaimer {
			position: absolute;
			bottom: 8px;
			width: 100%;
			color: #fff;
			white-space: nowrap;
			font-size: 1vw;
		}
		// end of add new rule to nav 2018-03-28
	}
	p {
		font-family: $heading_regular;
		font-size: 1.750em;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 0;
		position: relative;
		width: 100%;
		a {
			color: #fff;
		}
	}
	ul {
		padding-left: 30px;
	}
	li {
		display: inline-block;
		padding: 6px 15px;
		font-weight: 700;
		a {
			color: #fff;
			&:hover,
			&.current {
				color: $blue_lighter2;
			}
		}
	}

	// added rule in navigfation for disclaimer
	p[data-disclaimer] {
		@media (min-width: 768px) {
			&:after {
				content: attr(data-disclaimer);
				padding-top: 5px;
				color: #fff;
				font-size: .8rem;
				display: block;
				text-transform: lowercase;
			}
		}
	}
	&.opened {
		ul[data-disclaimer] {
			&:after {
				content: attr(data-disclaimer);
				position: relative;
    			bottom: -20px;
				color: #fff;
				font-size: .8rem;
				display: block;
				@media (min-width: 768px) {
					display: none;
				}
			}
		}
	}
	// end of added rule in navigfation for disclaimer
}
