.footer_top {
	background-color: $grey_bg1;
	padding: 60px 0 40px;

	li {
		display: inline-block;
		padding: 25px 8px;
		.en & {
			padding: 25px 5px;
		}
		a {
			font-size: 0.938em;

			&.current {
				color: $blue;
			}
		}

		&:first-of-type {
			padding-left: 0;
		}
		&:last-of-type {
			padding-right: 0;
		}
	}
	.left .title {
		margin-bottom: 0;
	}
	.right {
		.title {
			position: relative;
			margin-bottom: 23px;
			&:after {
				position: absolute;
				right: 0;
			}
		}
	}
}

.footer_bottom {
	background-color: $blue_dark;
	padding: 31px 0 45px;
	p,
	a {
		color: $blue_lighter;
		font-size: 0.875rem;
		font-weight: 400;
	}
}