 /* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
    /* GENERAL */
    .banner_top {
        .img_container {
            overflow: hidden;
            img {
                height: 300px;
                width: auto;
                float: right;
                margin-right: -60px;
            }
        }
        .fr & .banner_content.verylongFR {
            top: -80px;
            h1 {
                width: 70%;
            }
        }
    }

    /* HEADER */
    .main_nav li {
        padding: 30px 10px;
    }

    /* NAV */
    .wrist-hand,
    .ankle-foot {
        .nav_surgery_type .nav_title {
            height: 132px;
            &:after {
                border-width: 66px 0 66px 20px;
            }
        }
    }

    /* FOOTER */
    .footer_top {
        .grid-5 {
            width: 45%;
        }
        .grid-2 {
            width: 10%;
        }
    }
}

@media only screen and (max-width : 1100px) {
    .footer_top {
        .grid-5 {
            width: 50%;
        }
        .grid-2 {
            display: none;
        }
    }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
    /* DEFAULT */
    h1 {
        font-size: 2.8em;
        margin-bottom: 20px;
    }
    /* GENERAL */
    .banner_top {
        h1 {
            width: 76%;
        }
        h2 {
            width: 50%;
            font-size: 1.125rem;
            .contact & {
                width: 41.67%;
            }
        }
        .banner_content {
            top: -23px;
            &.long {
                top: -72px;
            }
        }

        .img_container {
            img {
                height: 250px;
                margin-right: -15%;
            }
        }

        &.small {
            h1 {
                width: 85%;
            }
            h2 {
                //width: 70%;
                .team & {
                    width: 40%;
                }
            }
        }

        .fr & .banner_content.longFR {
            top: -72px;
        }
    }
    .banner_color {
        padding: 70px 0;
    }

    /* HEADER */
    header {
        &.dropdown {
            height: 270px;
        }
        .orthopedics & {
            margin-bottom: 60px;
        }
    }
    .menu_btn {
        display: block;
    }
    .top_nav ul {
        display: none;
    }
    .main_nav {
        li.desktop {
            display: none;
        }
       .dropdown & .submenu {
            height: 170px;
       }
    }

    /* NAV */
    .nav_surgery_type {
        .nav_title {
            height: 132px;
            width: 20%;

            .wrist-hand &,
            .ankle-foot & {
                height: 150px;
            }

            .surgery_type & {
                height: 60px;
            }
            .surgery_type.wrist-hand &,
            .surgery_type.ankle-foot & {
                height: 70px;
            }

            &:after {
                border-width: 66px 0 66px 20px;
                .wrist-hand &,
                .ankle-foot & {
                    border-width: 75px 0 75px 20px;
                }
                .surgery_type & {
                    border-width: 30px 0 30px 15px;
                    right: -15px;
                }
                .surgery_type.wrist-hand &,
                .surgery_type.ankle-foot & {
                    border-width: 35px 0 35px 15px;
                }
            }
        }
        p {
            font-size: 1.5rem;
            line-height: 1.1;
            .surgery_type & {
                width: 100%;
                text-align: center;
            }
            .surgery_type.wrist-hand &,
            .surgery_type.ankle-foot & {
                text-align: left;
            }
        }
        li {
            padding: 5px 10px;
            a {
                font-size: 0.938rem;
            }
        }
        .grid-10 {
            width: 80%;
            .surgery_type & ul {
                display: none;
            }
        }
        ul {
            padding-left: 15px;
        }

        &.opened {
            .nav_title {
                height: 132px;
                &:after {
                    border-width: 66px 0 66px 20px;
                    right: -20px;
                }
            }
            .grid-10 ul {
                display: block;
            }
            a.see_all {
                display: none;
            }
        }
        .surgery_type.wrist-hand &.opened,
        .surgery_type.ankle-foot &.opened {
             .nav_title {
                height: 150px;
                &:after {
                    border-width: 75px 0 75px 20px;
                    right: -20px;
                }
            }
        }
    }
    .surgery_type a.see_all {
        display: block;
    }


    /* CONTENT */
    .reputation,
    .mention_honneur,
    .mot_direction,
    .visite,
    .engagement,
    .a_propos,
    .faq {
        .vcenter {
            display: block;
        }
        .grid-6 {
            width: 100%;
        }
        .col {
            padding-bottom: 40px;
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
    .employeurs {
        height: 500px;
    }
    .temoignages {
        height: 660px;
        .grid-6 {
            width: 70%;
        }
        .slide {
            background-position: 66%;
            &:first-of-type {
                background-position: 60%;
            }
        }
    }
    .implication {
        background-position: 80% 0;
        h2 {
            text-align: center;
        }
        .grid-6 {
            width: 70%;
        }
    }
    .specialites {
        .col {
            width: 100%;
            padding: 0 !important;
        }
        .grid-5 {
            margin-top: 30px;
        }
    }
    .overflow {
        .reverse {
            display: block;
            .grid-5 {
                margin-right: 0;
            }
        }
        .col {
            width: 100%;
            padding-left: 0 !important;
            padding-right: 0 !important;
            img {
                width: 100%;
            }
        }
    }
    .testimonials_list .box {
        width: 100%;
        padding: 0 !important;
    }
    .form_origin ul {
        width: 70%;
    }

    /* FOOTER */
    .footer_top {
        padding: 40px 0 20px;
        .grid-5 {
            width: 100%;
            text-align: left;
            &:first-of-type {
                padding-bottom: 20px;
            }
        }
        .right .title:after {
            right: auto;
        }
    }
    .footer_bottom {
        padding-bottom: 30px;
    }
}

// For testimonials banner_top only
@media only screen and (max-width : 840px) {
    .banner_top {
        .img_container_big {
            overflow: hidden;
            img {
                height: 320px;
                width: auto;
            }
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {
    /* DEFAULT */
    h1 {
        font-size: 2.5em;
    }
    h2 {
        font-size: 2em;
        &.title {
            font-size: 1.3rem;
        }
    }
    .grid-6 {
        width: 100%;
    }
    .main .col{
        &:nth-of-type(odd) {
            padding-right: 0;
        }
        &:nth-of-type(even) {
            padding-left: 0;
        }
    }
    .banner_top {
        h1,
        h2 {
            width: 100% !important;
            text-align: center;
        }
        h1 {
            word-wrap: break-word;
            &:after {
                margin: 20px auto 0;
            }
        }
        .author,
        .author_job {
            text-align: center;
        }
        .banner_content {
            position: relative;
            top: auto !important;
        }
        .surgery_type & {
            margin-top: 40px;
        }
        .cosmetic & h1 {
            width: 100%;
        }
        .contact & {
            margin-top: 0;
            h2 {
                width: 100%;
            }
            p {
                text-align: center;
            }
        }

        .img_container,
        .img_container_big {
            img {
                height: auto;
                width: 100%;
                float: none;
                margin-right: 0;
            }
        }
    }
    .sides {
        padding: 0;
    }
    .intro {
        width: 100%;
    }

    /* HEADER */
    header {
        height: auto;
        padding-bottom: 20px;
        margin-bottom: 50px;
        .grid-2 {
            width: 25%;
        }
        .top_nav {
            width: 75%;
        }
    }
    .main_nav {
        display: none;
    }
    .mobile_nav li.tablet {
        display: block;
    }

    /* NAV */
    .nav_slider {
        padding: 0 10px;
        .prev i,
        .next i {
            font-size: 50px;
        }
    }
    .nav_surgery_type {
        .surgery_type & .vcenter {
            display: block;
        }
        .nav_title {
            display: none;
            .surgery_type & {
                display: block;
                width: calc(100% + 20px);
                left: -10px;
                height: 45px !important;
                &:before,
                &:after {
                    display: none;
                }
            }
        }
        p {
            text-align: center !important;
        }
        .grid-10 {
            width: 100%;
            text-align: center;
            padding: 13px 0;
            ul {
                display: none;
            }
        }
        ul {
            margin-bottom: 20px;
            padding-left: 0;
        }
        li {
            display: block;
            padding: 6px 10px;
        }
        &.opened {
            a.see_all {
                display: block;
                margin-bottom: 20px;
            }
        }
    }
    a.see_all {
        display: block;
        padding-left: 0;
    }

    /* CONTENT */
    .mention_honneur img {
        width: 200px;
    }
    .temoignages {
        height: auto;
        .grid-6 {
            width: 100%;
        }
        .slide {
            padding: 50px 0;
            position: relative;
            background-image: none !important;
            background-color: #fff;
            @include transition(none);
        }
        q {
            padding: 0 30px;
        }
    }
    .employeurs {
        background-image: none;
        background-color: $grey_bg2;
    }
    .implication {
        background-image: none;
        background-color: $grey_bg2;
        .grid-6 {
            width: 100%;
        }
    }
    .conseils li {
        width: 100%;
        display: block;
    }
    .list_team li p {
        padding-right: 0;
    }
    .surgery_type_descr img {
        display: block;
        margin: 0 auto 40px;
        padding: 0;
    }
    .downloads li {
        width: 100%;
        height: 100px;
        float: none;
    }
    .form {
        .grid-5,
        .grid-7 {
            width: 100%;
            padding: 0 !important;
        }
        .full_border {
            margin-bottom: 50px;
            label {
                margin-bottom: 15px;
            }
        }
    }
    .voyage {
        padding: 100px 0;
    }
    .chirurgies_title {
        padding-top: 70px;
    }
    .banner_cosmetic {
        padding: 100px 0;
        p {
            width: 100%;
        }
    }
    .employers .before_ban {
        margin-bottom: -70px;
    }
    .banner_employers p {
        width: 100%;
    }
    .form_origin {
        ul {
            width: 80%;
        }
        li {
            height: 70px;
        }
    }
    .contact-map {
        .map {
            height: 300px;
        }
        .adresse {
            position: relative;
            width: calc(100% + 20px);
            padding: 30px 25px 15px;
            left: -10px;
            right: -10px;
            bottom: auto;
        }
    }

    /* FOOTER */
    .footer_top {
        li {
            display: block;
            padding: 7px 0;
            .en & {
                padding: 7px 0;
            }
        }
        .left .title {
            margin-bottom: 10px;
        }
        .right .title {
            margin-bottom: 33px;
        }
    }
    .footer_bottom {
        .col {
            width: 100%;
            text-align: center;
            margin-bottom: 7px;
        }
        p {
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width : 620px) {
    /* HEADER */
    .top_nav .rdv_btn {
        display: none;
    }
    .mobile_nav .rdv_btn {
        display: block;
    }
    .menu_btn {
        margin-top: 35px;
    }
    .logo img {
        margin-top: 8px;
    }
    header {
        .grid-2 {
            width: 140px;
        }
        .top_nav {
            width: auto;
        }
    }

}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
    /* DEFAULT */
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }

    /* NAV */
    .btn.big {
        width: 100%;
    }

    /* CONTENT */
    .mention_honneur img {
        width: 140px;
    }
    .list_team {
        padding-bottom: 80px;
        ul {
            margin-top: 75px;
        }
        li {
            margin: 70px 0;
            h2 {
                font-size: 1.7rem;
                line-height: 1.1;
            }
            .profile_content {
                top: auto;
                bottom: 82%;
                left: 10px;
            }
            p.long {
                padding-right: 0;
            }
        }
        .center_team {
            margin-top: 0;
            li {
                margin-top: 120px;
            }
        }
    }
    .testimonials_list .box_content {
        padding: 40px 30px;
    }
    .form_origin {
        ul {
            width: 100%;
        }
        li {
            width: 100%;
            height: 60px;
            margin: 1px 0;
        }
    }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
    /* CONTENT */
    .overflow {
        padding-bottom: 40px;
        .content {
            top: -110px;
        }
        &:last-of-type,
        &.last {
            margin-bottom: -70px;
        }
    }
    .symptomes {
        .intro {
            padding-bottom: 200px;
        }
        .references {
            padding-top: 50px;
        }
    }
    .chirurgien,
    .innov_descr,
    .employers .employers_descr {
        padding-bottom: 170px;
    }
    .employers .etapes_title {
        margin-bottom: 170px;
    }
    
}