.banner_top {
	position: relative;

	.banner_content {
		position: absolute;
		width: 100%;
		top: -25px;
		&.long {
			top: -80px;
		}
		.fr &.longFR {
			top: -80px;
		}
	}
	h1 {
		width: 58.33%;

		.cosmetic & {
			width: 40%;
		}
	}
	h2 {
		width: 41.67%;
		font-family: $font;
		color: $grey_font;
		text-transform: none;
		font-size: 1.250em;
		line-height: 1.3;
		text-align: left;
	}
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	.surgery_type & {
		margin-top: 140px;
	}
	.contact & {
		.banner_content {
			top: 0;
			position: relative;
		}
		h2 {
			float: left;
			margin-right: 8.33%;
		}
		p {
			font-weight: 400;
		}
	}
}

.banner_color {
	padding: 100px 0;
	h2,
	p {
		color: #fff;
	}
}

.overflow {
	padding-bottom: 90px;
	&:last-of-type,
	&.last {
		padding-bottom: 0;
		margin-bottom: -130px;
	}
	.content {
		position: relative;
		top: -210px; 
	}
	.col {
		margin-bottom: 30px;
		&:last-of-type {
			margin-bottom: 15px;
		}
		&.odd {
			padding-left: 0;
			padding-right: 10px;
		}
		&.even {
			padding-left: 10px;
			padding-right: 0;
		}
		&.nomargin {
			margin: 0;
		}
	}
	.reverse .grid-5 {
		margin-right: 8.33%;
	}
	h2 {
		text-align: left;
	}
	img {
		margin-top: 10px;
	}
}

.share {
	background-color: $grey_bg2;
	padding: 28px 0;
	
	ul {
		text-align: center;
	}
	li {
		display: inline-block;
		margin-right: 2px;
	}
	.atclear {
		display: none;
	}
	.int-patients & {
		background-color: $grey_bg1;
		position: relative;
		.content {
			height: 44px;
		}
		ul {
			position: absolute;
			left: calc(50% - 98px);
		}
	}
}
