header {
	margin-bottom: 110px;
	height: 126px;
	border-bottom: 0;
	overflow: hidden;
	@include transition();

	.content {
		position: relative;
	}

	&.dropdown {
		height: 300px;
		border-bottom: 1px solid $border;
	}

	.surgery_type & {
		margin-bottom: 0;
	}
	.contact & {
		margin-bottom: 25px;
	}
	.sitemap & {
		margin-bottom: 50px;
	}
}

.logo {
	img {
		max-width: 100%;
		margin-top: 12px;
	}
}

.menu_btn {
	display: none;
	height: 17px;
	width: 80px;
	position: relative;
	float: right;
	text-transform: uppercase;
	color: $blue;
	font-weight: 900;
	font-size: 0.875rem;
	line-height: 17px;
	margin: 20px 0 0 25px;

	span,
	span:before,
	span:after {
		position: absolute;
		height: 3px;
		width: 25px;
		top: 7px;
		left: 0;
		background-color: $blue;
	}
	
	span:before {
		content: '';
		top: -7px;
	}
	span:after {
		content: '';
		top: 7px;
	}
}
