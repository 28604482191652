/** HOME **/
.reputation {
	padding: 80px 0 50px;
	h2 {
		padding: 0 20px;
	}
}
.mention_honneur {
	padding: 150px 0;
	background: url('../images/banners/mention-d-honneur.png') no-repeat center;
	background-size: cover;
}
.temoignages {
	height: 600px;
	position: relative;
	.slide {
		position: absolute;
		height: 100%;
		width: 100%;
		padding: 0 70px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
.temoignage_content {
	margin-bottom: 50px;
}
.employeurs {
	height: 600px;
	background: url('../images/banners/employeur-accueil.jpg') no-repeat center;
	background-size: cover;
	.col {
		float: right;
	}
}


/** CLINIQUE **/
.mot_direction {
	padding: 80px 0 100px;
}
.visite {
	background-color: $blue_dark;
}
.engagement {
	padding: 75px 0;
}
.a_propos {
	background-color: $grey_bg2;
	padding: 80px 0;
}
.faq {
	padding: 70px 0;
	strong {
		display: block;
		margin-bottom: 8px;
	}
	p {
		margin-bottom: 30px;
	}
}
.implication {
	background: url('../images/banners/implication-sociale.jpg') no-repeat right;
	background-size: cover;
	padding: 110px 0;	
	h2 {
		text-align: left;
	}
}


/** EQUIPE **/
.list_team {
	padding: 60px 0 120px;

	ul {
		margin-top: 45px;
	}
	li {
		position: relative;
		margin: 40px 0;
		a {
			display: block;
			background-color: #000;
			&:hover img {
				opacity: 0.9;
			}
		}
		img {
			width: 100%;
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			backface-visibility: hidden;
			@include transition();
		}
		.profile_content {
			position: absolute;
			top: -18px;
			left: 20px;
		}
		h2 {
			font-size: 2.188rem;
			margin-bottom: 5px;
			text-align: left;
			line-height: 1.1;
		}
		p {
			font-size: 0.938rem;
			font-weight: 400;
			color: $grey_font;
			padding-right: 160px;
			&.long {
				padding-right: 200px;
			}
		}
	}
	.center_team li {
		margin: 80px auto 40px;
		float: none;
	}
}
.presentation {
	padding: 50px 0 110px;
}
.specialites {
	background-color: $grey_bg2;
	padding-bottom: 70px;
	h2 {
		text-align: left;
		margin-top: -25px;
	}
	ul {
		margin-bottom: 20px;
	}
}
.conseils {
	padding: 90px 0;
	ul {
		text-align: center;
		padding-top: 20px;
	}
	li {
		width: 16.66%;
		display: inline-block;
		padding: 0 10px;
		text-align: left;

		p {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 0.938em;
			color: $grey_font;
			margin-top: 10px;
		}
		img {
			max-width: 100%;
		}
	}
}


/** ORTHOPEDIE **/
.symptomes {
	padding-top: 75px;

	.intro {
		margin-bottom: 0;
		padding-bottom: 295px;
	}

	strong {
		color: #000;
		font-size: 1.250rem;
	}
	.references {
		font-size: 0.875rem;
		color: $grey_font;
		text-align: center;
		padding-top: 80px;
	}
}
.surgery_type_descr {
	padding: 60px 0 100px;

	.surgery_type_btn {
		padding-top: 70px;
	}
	img {
		padding: 20px;
	}
}
.downloads {
	li {
		float: left;
		width: 33.33%;
		height: 120px;

		&:nth-of-type(1) {
			background-color: $blue_dark;
		}
		&:nth-of-type(2) {
			background-color: $blue;
		}
		&:nth-of-type(3) {
			background-color: $blue_light;
		}
		a {
			height: 100%;
			width: 100%;
			padding: 20px;
			text-align: center;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;

			&:hover {
				background-color: rgba(0,0,0,0.10);
			}
		}
		span {
			width: 100%;
			font-size: 0.938rem;
			font-weight: 700;
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			display: block;
		}
		i {
			padding-right: 15px;
			font-size: 17px;
		}

		&.tocome {
			line-height: 1.15;
			padding: 20px;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			text-align: center;
		}
	}
}
.types_protheses {
	strong {
		color: #000;
		font-size: 1.250rem;
	}
	.type {
		font-style: italic;
	}
}


/** PATIENTS INTERNATIONAUX **/
.int-patients {
	.disc {
		margin-bottom: 20px;
	}
}
.certification {
	padding: 80px 0 275px;

	h2 {
		width: 66.67%;
		margin: 0 auto 30px;
	}
	img {
		display: block;
		margin: 35px auto 0;
	}
}
.voyage {
	padding: 170px 0 200px;
	background: url('../images/banners/pays-fait-bon-vivre.jpg') no-repeat center;
	background-size: cover;
	margin-bottom: 290px;
	.center {
		margin: 0 auto;
	}
	h3 {
		@extend h2;
		margin-bottom: 10px;
	}
	h3,
	p {
		color: #fff;
	}
	p {
		font-weight: 700;
	}
}


/** CHIRURGIE ESTHETIQUE **/
.chirurgien {
	padding: 70px 0 300px;
}
.chirurgies_title {
	padding-top: 140px;
	h2 {
		text-align: center;
		margin-bottom: 0;
	}
}
.chirurgies {
	h3 {
		@extend h2;
		text-align: left;
		margin-bottom: 10px;
		&.clearCol {
			margin-top: 25px;
		}
	}
	li {
		font-size: 1.125rem;
	    font-weight: 300;
	    line-height: 1.2;
	    padding: 4px 0;
	}
}
.banner_cosmetic {
	padding: 215px 0;
	background: url('../images/banners/approche-meticulause.jpg') no-repeat center;
	background-size: cover;
	p {
		font-weight: 700;
		color: #fff;
		width: 66.67%;
		margin-bottom: 0;
	}
}


/** INNOVATIONS **/
.innov_descr {
	padding: 60px 0 315px;
}
.innovation .overflow {
	h3 {
		@extend h2;
	}
}


/** TEMOIGNAGES **/
.testimonials_list {
	padding: 110px 0 60px;
	.box {
		margin-bottom: 60px;
		&.odd {
			padding-right: 30px;
		}
		&.even {
			padding-left: 30px;
			float: right;
		}
	}
	.box_content {
		background-color: $grey_bg2;
		padding: 50px 40px;
		img {
			display: block;
			margin: 0 auto 30px;
		}
	}
}


/** EMPLOYEURS **/
.employers {
	counter-reset: my-counter;
	.employers_descr {
		padding: 70px 0 300px;
	}
	.before_ban {
		padding-bottom: 0;
		margin-bottom: -30px;
	}
	.etapes_title {
		margin: 100px 0 275px;
	}
	.overflow {
		h3 {
			font-size: 1.125rem;
			font-weight: 300;
			margin-bottom: 15px;
			&:before {
				content: counter(my-counter, decimal-leading-zero);
				counter-increment: my-counter;
				@extend h2;
				display: block;
				margin-bottom: 10px;
			}
			&.clearCol {
				margin-top: 30px;
			}
		}
		&:last-of-type {
			margin-bottom: -90px;
		}
	}
}
.banner_employers {
	padding: 100px 0 150px;
	background: url('../images/banners/mention-d-honneur.png') no-repeat center;
	background-size: cover;
	i {
		display: block;
		color: $yellow;
		font-size: 100px;
		margin-bottom: 20px;
	}
	h2 {
		margin-bottom: 15px;
	}
	p {
		font-weight: 700;
		width: 66.67%;
	}
}


/** PRENDRE UN RENDEZ-VOUS **/
.contact-map {
	margin-top: 20px;
	.content {
		position: relative;
	}
	.map {
		height: 500px;
		width: 100%;
	}
	.adresse {
		position: absolute;
		background-color: #444;
		padding: 40px 25px;
		bottom: 50px;
		p {
			color: #fff;
			font-size: 1.250rem;
      font-weight: bold;

      .warning {
        color: #f98407;
        & > em { text-decoration: underline; }
      }

      .alt-addr {
        font-size: 0.8em;
      }
		}
	}
}
.form {
	padding: 60px 0 100px;
	background-color: #fff;
	form {
		padding-top: 40px;
	}
	.col.odd {
		padding-left: 0;
		padding-right: 10px;
	}
	.col.even {
		padding-left: 10px;
		padding-right: 0;
	}
	.birthday {
		.col {
			padding: 0 7px;
			&.first {
				padding-left: 0;
				padding-right: 14px; 
			}
			&.last {
				padding-left: 14px;
				padding-right: 0; 
			}
		}
	}
	.birthday,
	.gender {
		margin-top: 30px;
	}
	.full_border {
		border-bottom: 1px solid $border;
		margin-bottom: 70px;
		&.last {
			margin-bottom: 40px;
		}
		md-input-container {
			margin: 10px 0;
		}
		label {
			@include transform(none !important);
		}
		md-radio-group md-radio-button:not(:first-child) {
			margin-top: 0;
		}
	}
	.reference {
		width: auto;
	}
	.ramq {
		padding-top: 90px;
		p {
			font-size: 1rem;
		}
		.heading {
			text-transform: uppercase;
			color: #000;
			font-size: 1.250rem;
			font-weight: 400;
		}
	}
	.submit_form {
		padding-top: 70px;
		a {
			padding: 19px 20px;
		}
	}
}
.form_origin {
	ul {
		width: 50%;
		margin: 0 auto;
	}
	li {
		float: left;
		width: 49%;
		height: 60px;
		padding: 15px;
		margin: 0 1px;
		background-color: $border;
		cursor: pointer;
		outline: none;
		@include transition();
		
		&:hover,
		&.current {
			background-color: $blue;
		}
		span {
			width: 100%;
			text-transform: uppercase;
			text-align: center;
			font-size: 0.938rem;
			font-weight: 700;
			line-height: 1.3;
			color: #fff;
			display: block;
		}
	}
}
.success {
	padding: 80px 0 120px;
	h2 {
		font-family: $font;
		font-size: 1.875rem;
		font-weight: 700;
		color: $blue_light;
		text-transform: uppercase;
	}
	.btn {
		margin-top: 60px;
	}
}


/** SITEMAP **/
.sitemap section {
	padding-bottom: 100px;
	li {
		padding: 7px 0;
	}
	span {
		display: block;
		margin-bottom: 5px;
	}
	ul ul li {
		padding: 6px 0;
	}
	ul ul ul li {
		padding: 4px 0;
		&:first-of-type {
			padding-top: 6px;
		}
	}
}

/** ERREUR 404 **/
.error404 section {
	padding-bottom: 100px;
	h2 {
		text-align: left;
		font-family: $font;
		font-size: 1.250em;
		color: $grey_font;
		text-transform: none;
		font-weight: 400;
	}
}
