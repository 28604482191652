/* COLORS */
$blue_dark: #005288;
$blue: #1972c5;
$blue_light: #338bea;
$blue_lighter: #7dbafb;
$blue_lighter2: #89d0fd;

$grey_font: #555;
$grey_light: #999;
$grey_bg1: #e1e1e0;
$grey_bg2: #f2f3f2;
$border: #bbb;

$yellow: #eccc5b;

$error: #dd2c00;

/* TYPOGRAPHY */
@font-face {
    font-family: 'butlermedium';
    src: url('../fonts/butler_medium.eot');
    src: url('../fonts/butler_medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/butler_medium.woff2') format('woff2'),
         url('../fonts/butler_medium.woff') format('woff'),
         url('../fonts/butler_medium.ttf') format('truetype'),
         url('../fonts/butler_medium.svg#butlermedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'butlerregular';
    src: url('../fonts/butler_regular.eot');
    src: url('../fonts/butler_regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/butler_regular.woff2') format('woff2'),
         url('../fonts/butler_regular.woff') format('woff'),
         url('../fonts/butler_regular.ttf') format('truetype'),
         url('../fonts/butler_regular.svg#butlerregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


$font: 'Lato', sans-serif;
$heading: 'butlermedium', sans-serif;
$heading_regular: 'butlerregular', sans-serif;


/* MIXIN */
@mixin transition($param: all ease 0.5s){
	-webkit-transition: $param;
    -moz-transition: $param;
    -ms-transition: $param;
    -o-transition: $param;
    transition: $param;
}
@mixin animation($param){
    -webkit-animation: $param;
    -moz-animation: $param;
    -ms-animation: $param;
    -o-animation: $param;
    animation: $param;
}
@mixin box-shadow(){
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
}
@mixin transform($param){
    -webkit-transform: $param;
    -moz-transform: $param;
    -ms-transform: $param;
    -o-transform: $param;
    transform: $param;
}


/* GRID */
.grid-1 {
    width: 8.33%;
}
.grid-2 {
    width: 16.66%;
}
.grid-3 {
    width: 25%;
}
.grid-4 {
    width: 33.33%;
}
.grid-5 {
    width: 41.67%;
}
.grid-6 {
    width: 50%;
}
.grid-7 {
    width: 58.33%;
}
.grid-8 {
    width: 66.67%;
}
.grid-9 {
    width: 75%;
}
.grid-10 {
    width: 83.33%;
}
.grid-11 {
    width: 91.66%;
}
.grid-12 {
    width: 100%;
}


/* ANIMATIONS */
.fade {
    @include transition();
    opacity: 1;
    &.slide {
        @include transition(all 0.8s ease);
    }
}
.fade.ng-hide,
.fade.ng-enter,
.fade.ng-leave.ng-leave-active {
    opacity: 0;
}
.fade.ng-leave,
.fade.ng-enter.ng-enter-active {
    opacity: 1;
}