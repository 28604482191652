@import "general/reset";

/* Variables + mixins */
@import "general/vars";
@import "general/icons";

/* Default */
@import "general/default";

/* Navigation */
@import "general/nav";

/* General */
@import "general/elements";
@import "general/form";

/* Header */
@import "content/header";

/* Footer */
@import "content/footer";

/* Content */
@import "content/figures";
@import "content/pages";

/* Responsive */
@import "general/breakpoints";