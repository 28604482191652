/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, .voyage h3, .chirurgies h3, .innovation .overflow h3, .employers .overflow h3:before, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Variables + mixins */
/* COLORS */
/* TYPOGRAPHY */
@font-face {
  font-family: 'butlermedium';
  src: url("../fonts/butler_medium.eot");
  src: url("../fonts/butler_medium.eot?#iefix") format("embedded-opentype"), url("../fonts/butler_medium.woff2") format("woff2"), url("../fonts/butler_medium.woff") format("woff"), url("../fonts/butler_medium.ttf") format("truetype"), url("../fonts/butler_medium.svg#butlermedium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'butlerregular';
  src: url("../fonts/butler_regular.eot");
  src: url("../fonts/butler_regular.eot?#iefix") format("embedded-opentype"), url("../fonts/butler_regular.woff2") format("woff2"), url("../fonts/butler_regular.woff") format("woff"), url("../fonts/butler_regular.ttf") format("truetype"), url("../fonts/butler_regular.svg#butlerregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* MIXIN */
/* GRID */
.grid-1 {
  width: 8.33%; }

.grid-2 {
  width: 16.66%; }

.grid-3 {
  width: 25%; }

.grid-4 {
  width: 33.33%; }

.grid-5 {
  width: 41.67%; }

.grid-6 {
  width: 50%; }

.grid-7 {
  width: 58.33%; }

.grid-8 {
  width: 66.67%; }

.grid-9 {
  width: 75%; }

.grid-10 {
  width: 83.33%; }

.grid-11 {
  width: 91.66%; }

.grid-12 {
  width: 100%; }

/* ANIMATIONS */
.fade {
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 1; }
  .fade.slide {
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease; }

.fade.ng-hide,
.fade.ng-enter,
.fade.ng-leave.ng-leave-active {
  opacity: 0; }

.fade.ng-leave,
.fade.ng-enter.ng-enter-active {
  opacity: 1; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?frm11j");
  src: url("../fonts/icomoon.eot?frm11j#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff2?frm11j") format("woff2"), url("../fonts/icomoon.ttf?frm11j") format("truetype"), url("../fonts/icomoon.woff?frm11j") format("woff"), url("../fonts/icomoon.svg?frm11j#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-download:before {
  content: "\e904"; }

.icon-info:before {
  content: "\e905"; }

.icon-arrow:before {
  content: "\e900"; }

.icon-calendar:before {
  content: "\e901"; }

.icon-facebook:before {
  content: "\e902"; }

.icon-linkedin:before {
  content: "\e903"; }

.icon-dropdown:before {
  content: "\e906"; }

/* Default */
/* 
  Allow angular.js to be loaded in body, hiding cloaked elements until 
  templates compile.  The !important is important given that there may be 
  other selectors that are more specific or come later and might alter display.  
 */
[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }

body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #555;
  -webkit-text-size-adjust: none; }
  body.overlay {
    overflow: hidden; }

a {
  text-decoration: none;
  outline: none;
  color: #555;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s; }
  .main a {
    color: #1972c5; }

a:hover {
  text-decoration: none;
  outline: none;
  color: #1972c5; }

p {
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px; }

h1 {
  font-family: "butlermedium", sans-serif;
  font-size: 3.125em;
  color: #000;
  margin-bottom: 30px;
  text-transform: uppercase;
  line-height: 1.1; }
  h1:after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: #1972c5;
    display: block;
    margin-top: 20px; }

h2, .voyage h3, .chirurgies h3, .innovation .overflow h3, .employers .overflow h3:before {
  font-family: "butlermedium", sans-serif;
  font-size: 2.5em;
  color: #000;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3; }

h3 {
  line-height: 1.3; }

h4 {
  line-height: 1.3;
  font-weight: 700;
  color: #000;
  font-size: 1.250rem;
  margin-bottom: 20px; }

ol {
  list-style-type: decimal;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.3;
  padding-left: 20px; }
  ol li {
    padding-bottom: 5px; }

q {
  font-size: 1.125em;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 20px;
  display: block;
  line-height: 1.3; }

strong {
  font-weight: bold; }

sup {
  vertical-align: top;
  font-size: 0.6em; }

a img {
  display: block; }

.cf:after, .content:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

* html .cf, * html .content {
  zoom: 1; }

/* IE6 */
*:first-child + html .cf, *:first-child + html .content {
  zoom: 1; }

/* IE7 */
/* Navigation */
/* MENU */
.top_nav,
.main_nav {
  width: 83.33%;
  float: right;
  text-align: right; }
  .top_nav li,
  .main_nav li {
    display: inline-block; }
    .top_nav li a,
    .main_nav li a {
      font-size: 0.938em;
      line-height: 1; }

.top_nav ul {
  float: right;
  margin-right: 15px; }

.top_nav li {
  padding: 17px 10px; }
  .top_nav li a.current {
    color: #1972c5; }

.top_nav .social_medias {
  font-size: 1em; }
  .top_nav .social_medias i {
    color: #999;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s; }
    .top_nav .social_medias i:hover {
      color: #1972c5; }

.main_nav li {
  padding: 30px 15px; }
  .main_nav li a {
    font-weight: 700;
    text-transform: uppercase; }
    .main_nav li a.active, .main_nav li a.current {
      color: #1972c5; }

.main_nav .submenu {
  position: absolute;
  width: 100%;
  left: 0;
  padding: 30px 0 20px;
  background-color: #fff;
  height: 0;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s; }
  .main_nav .submenu li {
    padding: 0 10px;
    width: 16.66%;
    float: left; }
    .main_nav .submenu li img {
      width: 100%; }
    .main_nav .submenu li span {
      display: block;
      text-align: left;
      margin-top: 10px; }
  .main_nav .submenu.innov_submenu {
    text-align: center; }
    .main_nav .submenu.innov_submenu li {
      float: none;
      white-space: nowrap; }
  .dropdown .main_nav .submenu {
    height: 195px; }

.mobile_nav {
  background-color: #e1e1e0;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  z-index: 9;
  top: 0; }
  .mobile_nav .content {
    overflow: scroll;
    height: 100%;
    position: absolute; }
  .mobile_nav ul {
    padding-top: 55px;
    text-align: center; }
  .mobile_nav li {
    display: block;
    padding: 13px 5px;
    border-bottom: 1px solid #bbb; }
    .mobile_nav li:last-of-type {
      border-bottom: 0; }
    .mobile_nav li.tablet {
      display: none; }
    .mobile_nav li a {
      font-size: 1rem;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase; }
      .mobile_nav li a.active, .mobile_nav li a.current {
        color: #1972c5; }
      .mobile_nav li a.current + .submenu {
        display: block !important;
        height: auto; }
  .mobile_nav .submenu {
    padding-top: 10px;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.3s ease;
    -moz-transition: height 0.3s ease;
    -ms-transition: height 0.3s ease;
    -o-transition: height 0.3s ease;
    transition: height 0.3s ease; }
    .mobile_nav .submenu li {
      border-bottom: 0;
      padding: 7px 5px; }
    .mobile_nav .submenu a {
      font-size: 0.813rem; }
  .dropdown .mobile_nav .orthoSubmenu {
    height: 190px; }
  .dropdown .mobile_nav .innovSubmenu {
    height: 100px; }
  .mobile_nav .nav_social_medias {
    padding: 30px 0; }
    .mobile_nav .nav_social_medias li {
      display: inline-block;
      border-bottom: 0;
      padding: 0 10px; }
  .mobile_nav .rdv_btn {
    display: none;
    float: none;
    width: 280px;
    margin: 0 auto;
    text-align: center;
    padding: 14px 10px 17px; }

.close_menu_btn {
  height: 25px;
  width: 25px;
  position: absolute;
  right: 20px;
  top: 20px; }
  .close_menu_btn span:before,
  .close_menu_btn span:after {
    position: absolute;
    height: 3px;
    width: 25px;
    top: 10px;
    left: 0;
    background-color: #555; }
  .close_menu_btn span:before {
    content: '';
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); }
  .close_menu_btn span:after {
    content: '';
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }

/* BTN */
.btn {
  color: #1972c5;
  font-size: 0.938em;
  font-weight: 700;
  border: 2px solid #1972c5;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  width: 280px;
  display: block;
  margin: 0 auto; }
  .btn:hover {
    background: #1972c5;
    color: #fff; }
  .btn.big {
    width: 360px;
    padding: 20px;
    font-size: 1.125rem; }
  .home .btn {
    margin-top: 40px; }

.rdv_btn {
  float: right;
  background-color: #1972c5;
  padding: 14px 25px 17px;
  color: #fff;
  font-size: 0.938em;
  font-weight: 700;
  text-transform: uppercase; }
  .rdv_btn:before {
    content: '';
    width: 16px;
    height: 18px;
    background: url("../images/calendar.png") no-repeat;
    display: inline-block;
    padding-right: 10px;
    position: relative;
    top: 2px; }
  .rdv_btn:hover {
    background-color: #338bea;
    color: #fff; }

a.see_all {
  display: none;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  padding-left: 30px; }

/* NAV / PAGER */
.nav_slider {
  position: absolute;
  width: 100%;
  top: calc(50% - 36px);
  padding: 0 20px; }
  .nav_slider .prev {
    float: left; }
  .nav_slider .next {
    float: right; }
    .nav_slider .next i {
      display: block;
      -webkit-transform: scale(-1);
      -moz-transform: scale(-1);
      -ms-transform: scale(-1);
      -o-transform: scale(-1);
      transform: scale(-1); }
  .nav_slider .prev i,
  .nav_slider .next i {
    font-size: 73px;
    color: #1972c5; }

.nav_surgery_type {
  background-color: #005288; }
  .nav_surgery_type .col {
    padding: 10px 0; }
  .nav_surgery_type .nav_title {
    background-color: #1972c5;
    position: relative;
    height: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center; }
    .nav_surgery_type .nav_title:before {
      content: '';
      position: absolute;
      background-color: #1972c5;
      height: 100%;
      width: 1000px;
      top: 0;
      z-index: 0;
      left: -850px; }
    .nav_surgery_type .nav_title:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 60px 0 60px 20px;
      border-color: transparent transparent transparent #1972c5;
      position: absolute;
      top: 0;
      right: -20px; }
    .nav_surgery_type .nav_title .dislaimer {
      position: absolute;
      bottom: 8px;
      width: 100%;
      color: #fff;
      white-space: nowrap;
      font-size: 1vw; }
  .nav_surgery_type p {
    font-family: "butlerregular", sans-serif;
    font-size: 1.750em;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 0;
    position: relative;
    width: 100%; }
    .nav_surgery_type p a {
      color: #fff; }
  .nav_surgery_type ul {
    padding-left: 30px; }
  .nav_surgery_type li {
    display: inline-block;
    padding: 6px 15px;
    font-weight: 700; }
    .nav_surgery_type li a {
      color: #fff; }
      .nav_surgery_type li a:hover, .nav_surgery_type li a.current {
        color: #89d0fd; }
  @media (min-width: 768px) {
    .nav_surgery_type p[data-disclaimer]:after {
      content: attr(data-disclaimer);
      padding-top: 5px;
      color: #fff;
      font-size: .8rem;
      display: block;
      text-transform: lowercase; } }
  .nav_surgery_type.opened ul[data-disclaimer]:after {
    content: attr(data-disclaimer);
    position: relative;
    bottom: -20px;
    color: #fff;
    font-size: .8rem;
    display: block; }
    @media (min-width: 768px) {
      .nav_surgery_type.opened ul[data-disclaimer]:after {
        display: none; } }

/* General */
.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px; }

.col {
  float: left; }
  .main .col:nth-of-type(odd) {
    padding-right: 10px; }
  .main .col:nth-of-type(even) {
    padding-left: 10px; }
  .col img {
    max-width: 100%; }

.right {
  text-align: right; }

.center {
  text-align: center; }

.vcenter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center; }

.fright {
  float: right; }

.reverse {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.sides {
  padding: 0 60px; }
  .sides h2, .sides .voyage h3, .voyage .sides h3, .sides .chirurgies h3, .chirurgies .sides h3, .sides .innovation .overflow h3, .innovation .overflow .sides h3, .sides .employers .overflow h3:before, .employers .overflow .sides h3:before {
    margin-bottom: 20px; }

.clearCol {
  clear: both; }

.grey {
  background-color: #f2f3f2; }

.title {
  font-size: 1.250em;
  color: #000;
  font-weight: 700; }
  .title:after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: #1972c5;
    display: block;
    margin-top: 20px; }

h2.title, .voyage h3.title, .chirurgies h3.title, .innovation .overflow h3.title, .employers .overflow h3.title:before {
  font-family: "Lato", sans-serif;
  font-size: 1.563rem;
  font-weight: 400; }
  h2.title:after, .voyage h3.title:after, .chirurgies h3.title:after, .innovation .overflow h3.title:after {
    margin: 20px auto 0; }

.subtitle {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.938em; }

.intro {
  width: 83.33%;
  text-align: center;
  margin: 0 auto 20px;
  font-size: 1.125em;
  font-weight: 300; }

.author {
  color: #000;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 10px; }

.author_job {
  font-size: 0.875rem;
  font-weight: 300; }

.video_container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden; }
  .video_container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.disc {
  margin-left: 20px; }
  .disc li {
    list-style-type: disc;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.2;
    padding: 4px 0; }

input::-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset; }

button,
input,
select,
textarea {
  font-family: "Lato", sans-serif;
  outline: none; }

input,
select,
textarea {
  font-weight: 600;
  color: #000;
  background: none;
  font-size: 1.125rem; }

label {
  color: #999;
  font-weight: 600;
  display: block;
  font-size: 1.125rem;
  position: static !important; }
  label.required:after {
    content: '*'; }
  .birthday label,
  .gender label,
  .full_border label {
    color: #000; }

input,
textarea {
  border: 0;
  border-bottom: 1px solid #bbb; }

input[type="submit"] {
  padding: 17px !important;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s; }

legend {
  font-size: 1.250rem;
  color: #000;
  text-transform: uppercase;
  clear: both;
  padding: 50px 0; }

.error {
  color: #dd2c00;
  font-size: 0.875rem;
  font-weight: 400;
  padding-top: 5px;
  clear: both; }

/* MATERIAL */
md-input-container {
  width: 100%;
  padding-bottom: 2px; }
  md-input-container [ng-messages] {
    min-height: 0; }
  md-input-container .md-input {
    height: 35px;
    line-height: 35px;
    border-color: #bbb;
    color: #000 !important; }
  md-input-container .md-placeholder {
    color: #bbb; }
  md-input-container label.md-placeholder {
    color: #999; }
  md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #1972c5; }
  md-input-container:not(.md-input-invalid).md-input-focused .md-input {
    border-color: #1972c5; }
  md-input-container .md-char-counter {
    font-style: italic;
    color: #bbb; }

md-input-container .md-input,
md-input-container label,
md-input-container:not(.md-input-invalid).md-input-has-value label {
  color: #999; }

md-input-container.md-input-focused label:not(.md-no-float),
md-input-container.md-input-has-placeholder label:not(.md-no-float),
md-input-container.md-input-has-value label:not(.md-no-float) {
  -webkit-transform: translate3d(0, 6px, 0) scale(0.82);
  transform: translate3d(0, 6px, 0) scale(0.82); }

.md-select-value {
  min-height: 31px; }
  .md-select-value span {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    max-width: 95%; }
  .md-select-value .md-select-icon:after {
    content: '\e906';
    font-family: 'icomoon';
    color: #1972c5;
    font-size: 9px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }

md-select.md-default-theme .md-select-value,
md-select .md-select-value {
  border-color: #bbb; }
  md-select.md-default-theme .md-select-value.md-select-placeholder,
  md-select .md-select-value.md-select-placeholder {
    color: #999;
    font-weight: 400; }

md-select:not([disabled]):focus .md-select-value {
  border-bottom-color: #1972c5;
  color: #000; }

md-select-menu md-option:focus[selected],
md-select-menu md-option[selected] {
  color: #1972c5; }

md-select.md-default-theme[disabled] .md-select-value,
md-select[disabled] .md-select-value {
  border-bottom: 1px solid #bbb;
  background: none; }

md-option .md-text {
  overflow: visible; }

md-radio-button {
  display: inline-block;
  margin-bottom: 0;
  padding-right: 50px; }
  md-radio-button .md-on {
    background-color: #1972c5; }
  md-radio-button.md-checked .md-off {
    border-color: #1972c5; }
  md-radio-button span {
    font-weight: 700;
    font-size: 1.125rem;
    color: #000; }

md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
  background-color: rgba(25, 114, 197, 0.25); }

md-radio-group[disabled],
md-radio-button[disabled] {
  cursor: default; }
  md-radio-group[disabled] .md-container .md-off,
  md-radio-group[disabled] .md-container .md-on,
  md-radio-button[disabled] .md-container .md-off,
  md-radio-button[disabled] .md-container .md-on {
    cursor: default; }

md-autocomplete {
  background: none; }
  md-autocomplete[md-floating-label] md-input-container {
    padding-bottom: 0; }

.md-whiteframe-1dp,
.md-whiteframe-z1 {
  box-shadow: none; }

/* Header */
header {
  margin-bottom: 110px;
  height: 126px;
  border-bottom: 0;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s; }
  header .content {
    position: relative; }
  header.dropdown {
    height: 300px;
    border-bottom: 1px solid #bbb; }
  .surgery_type header {
    margin-bottom: 0; }
  .contact header {
    margin-bottom: 25px; }
  .sitemap header {
    margin-bottom: 50px; }

.logo img {
  max-width: 100%;
  margin-top: 12px; }

.menu_btn {
  display: none;
  height: 17px;
  width: 80px;
  position: relative;
  float: right;
  text-transform: uppercase;
  color: #1972c5;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 17px;
  margin: 20px 0 0 25px; }
  .menu_btn span,
  .menu_btn span:before,
  .menu_btn span:after {
    position: absolute;
    height: 3px;
    width: 25px;
    top: 7px;
    left: 0;
    background-color: #1972c5; }
  .menu_btn span:before {
    content: '';
    top: -7px; }
  .menu_btn span:after {
    content: '';
    top: 7px; }

/* Footer */
.footer_top {
  background-color: #e1e1e0;
  padding: 60px 0 40px; }
  .footer_top li {
    display: inline-block;
    padding: 25px 8px; }
    .en .footer_top li {
      padding: 25px 5px; }
    .footer_top li a {
      font-size: 0.938em; }
      .footer_top li a.current {
        color: #1972c5; }
    .footer_top li:first-of-type {
      padding-left: 0; }
    .footer_top li:last-of-type {
      padding-right: 0; }
  .footer_top .left .title {
    margin-bottom: 0; }
  .footer_top .right .title {
    position: relative;
    margin-bottom: 23px; }
    .footer_top .right .title:after {
      position: absolute;
      right: 0; }

.footer_bottom {
  background-color: #005288;
  padding: 31px 0 45px; }
  .footer_bottom p,
  .footer_bottom a {
    color: #7dbafb;
    font-size: 0.875rem;
    font-weight: 400; }

/* Content */
.banner_top {
  position: relative; }
  .banner_top .banner_content {
    position: absolute;
    width: 100%;
    top: -25px; }
    .banner_top .banner_content.long {
      top: -80px; }
    .fr .banner_top .banner_content.longFR {
      top: -80px; }
  .banner_top h1 {
    width: 58.33%; }
    .cosmetic .banner_top h1 {
      width: 40%; }
  .banner_top h2, .banner_top .voyage h3, .voyage .banner_top h3, .banner_top .chirurgies h3, .chirurgies .banner_top h3, .banner_top .innovation .overflow h3, .innovation .overflow .banner_top h3, .banner_top .employers .overflow h3:before, .employers .overflow .banner_top h3:before {
    width: 41.67%;
    font-family: "Lato", sans-serif;
    color: #555;
    text-transform: none;
    font-size: 1.250em;
    line-height: 1.3;
    text-align: left; }
  .banner_top img {
    width: 100%;
    height: auto;
    display: block; }
  .surgery_type .banner_top {
    margin-top: 140px; }
  .contact .banner_top .banner_content {
    top: 0;
    position: relative; }
  .contact .banner_top h2, .contact .banner_top .voyage h3, .voyage .contact .banner_top h3, .contact .banner_top .chirurgies h3, .chirurgies .contact .banner_top h3, .contact .banner_top .innovation .overflow h3, .innovation .overflow .contact .banner_top h3, .contact .banner_top .employers .overflow h3:before, .employers .overflow .contact .banner_top h3:before {
    float: left;
    margin-right: 8.33%; }
  .contact .banner_top p {
    font-weight: 400; }

.banner_color {
  padding: 100px 0; }
  .banner_color h2, .banner_color .voyage h3, .voyage .banner_color h3, .banner_color .chirurgies h3, .chirurgies .banner_color h3, .banner_color .innovation .overflow h3, .innovation .overflow .banner_color h3, .banner_color .employers .overflow h3:before, .employers .overflow .banner_color h3:before,
  .banner_color p {
    color: #fff; }

.overflow {
  padding-bottom: 90px; }
  .overflow:last-of-type, .overflow.last {
    padding-bottom: 0;
    margin-bottom: -130px; }
  .overflow .content {
    position: relative;
    top: -210px; }
  .overflow .col {
    margin-bottom: 30px; }
    .overflow .col:last-of-type {
      margin-bottom: 15px; }
    .overflow .col.odd {
      padding-left: 0;
      padding-right: 10px; }
    .overflow .col.even {
      padding-left: 10px;
      padding-right: 0; }
    .overflow .col.nomargin {
      margin: 0; }
  .overflow .reverse .grid-5 {
    margin-right: 8.33%; }
  .overflow h2, .overflow .voyage h3, .voyage .overflow h3, .overflow .chirurgies h3, .chirurgies .overflow h3, .innovation .overflow h3, .employers .overflow h3:before {
    text-align: left; }
  .overflow img {
    margin-top: 10px; }

.share {
  background-color: #f2f3f2;
  padding: 28px 0; }
  .share ul {
    text-align: center; }
  .share li {
    display: inline-block;
    margin-right: 2px; }
  .share .atclear {
    display: none; }
  .int-patients .share {
    background-color: #e1e1e0;
    position: relative; }
    .int-patients .share .content {
      height: 44px; }
    .int-patients .share ul {
      position: absolute;
      left: calc(50% - 98px); }

/** HOME **/
.reputation {
  padding: 80px 0 50px; }
  .reputation h2, .reputation .voyage h3, .voyage .reputation h3, .reputation .chirurgies h3, .chirurgies .reputation h3, .reputation .innovation .overflow h3, .innovation .overflow .reputation h3, .reputation .employers .overflow h3:before, .employers .overflow .reputation h3:before {
    padding: 0 20px; }

.mention_honneur {
  padding: 150px 0;
  background: url("../images/banners/mention-d-honneur.png") no-repeat center;
  background-size: cover; }

.temoignages {
  height: 600px;
  position: relative; }
  .temoignages .slide {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

.temoignage_content {
  margin-bottom: 50px; }

.employeurs {
  height: 600px;
  background: url("../images/banners/employeur-accueil.jpg") no-repeat center;
  background-size: cover; }
  .employeurs .col {
    float: right; }

/** CLINIQUE **/
.mot_direction {
  padding: 80px 0 100px; }

.visite {
  background-color: #005288; }

.engagement {
  padding: 75px 0; }

.a_propos {
  background-color: #f2f3f2;
  padding: 80px 0; }

.faq {
  padding: 70px 0; }
  .faq strong {
    display: block;
    margin-bottom: 8px; }
  .faq p {
    margin-bottom: 30px; }

.implication {
  background: url("../images/banners/implication-sociale.jpg") no-repeat right;
  background-size: cover;
  padding: 110px 0; }
  .implication h2, .implication .voyage h3, .voyage .implication h3, .implication .chirurgies h3, .chirurgies .implication h3, .implication .innovation .overflow h3, .innovation .overflow .implication h3, .implication .employers .overflow h3:before, .employers .overflow .implication h3:before {
    text-align: left; }

/** EQUIPE **/
.list_team {
  padding: 60px 0 120px; }
  .list_team ul {
    margin-top: 45px; }
  .list_team li {
    position: relative;
    margin: 40px 0; }
    .list_team li a {
      display: block;
      background-color: #000; }
      .list_team li a:hover img {
        opacity: 0.9; }
    .list_team li img {
      width: 100%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: all ease 0.5s;
      -moz-transition: all ease 0.5s;
      -ms-transition: all ease 0.5s;
      -o-transition: all ease 0.5s;
      transition: all ease 0.5s; }
    .list_team li .profile_content {
      position: absolute;
      top: -18px;
      left: 20px; }
    .list_team li h2, .list_team li .voyage h3, .voyage .list_team li h3, .list_team li .chirurgies h3, .chirurgies .list_team li h3, .list_team li .innovation .overflow h3, .innovation .overflow .list_team li h3, .list_team li .employers .overflow h3:before, .employers .overflow .list_team li h3:before {
      font-size: 2.188rem;
      margin-bottom: 5px;
      text-align: left;
      line-height: 1.1; }
    .list_team li p {
      font-size: 0.938rem;
      font-weight: 400;
      color: #555;
      padding-right: 160px; }
      .list_team li p.long {
        padding-right: 200px; }
  .list_team .center_team li {
    margin: 80px auto 40px;
    float: none; }

.presentation {
  padding: 50px 0 110px; }

.specialites {
  background-color: #f2f3f2;
  padding-bottom: 70px; }
  .specialites h2, .specialites .voyage h3, .voyage .specialites h3, .specialites .chirurgies h3, .chirurgies .specialites h3, .specialites .innovation .overflow h3, .innovation .overflow .specialites h3, .specialites .employers .overflow h3:before, .employers .overflow .specialites h3:before {
    text-align: left;
    margin-top: -25px; }
  .specialites ul {
    margin-bottom: 20px; }

.conseils {
  padding: 90px 0; }
  .conseils ul {
    text-align: center;
    padding-top: 20px; }
  .conseils li {
    width: 16.66%;
    display: inline-block;
    padding: 0 10px;
    text-align: left; }
    .conseils li p {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.938em;
      color: #555;
      margin-top: 10px; }
    .conseils li img {
      max-width: 100%; }

/** ORTHOPEDIE **/
.symptomes {
  padding-top: 75px; }
  .symptomes .intro {
    margin-bottom: 0;
    padding-bottom: 295px; }
  .symptomes strong {
    color: #000;
    font-size: 1.250rem; }
  .symptomes .references {
    font-size: 0.875rem;
    color: #555;
    text-align: center;
    padding-top: 80px; }

.surgery_type_descr {
  padding: 60px 0 100px; }
  .surgery_type_descr .surgery_type_btn {
    padding-top: 70px; }
  .surgery_type_descr img {
    padding: 20px; }

.downloads li {
  float: left;
  width: 33.33%;
  height: 120px; }
  .downloads li:nth-of-type(1) {
    background-color: #005288; }
  .downloads li:nth-of-type(2) {
    background-color: #1972c5; }
  .downloads li:nth-of-type(3) {
    background-color: #338bea; }
  .downloads li a {
    height: 100%;
    width: 100%;
    padding: 20px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center; }
    .downloads li a:hover {
      background-color: rgba(0, 0, 0, 0.1); }
  .downloads li span {
    width: 100%;
    font-size: 0.938rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: block; }
  .downloads li i {
    padding-right: 15px;
    font-size: 17px; }
  .downloads li.tocome {
    line-height: 1.15;
    padding: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    text-align: center; }

.types_protheses strong {
  color: #000;
  font-size: 1.250rem; }

.types_protheses .type {
  font-style: italic; }

/** PATIENTS INTERNATIONAUX **/
.int-patients .disc {
  margin-bottom: 20px; }

.certification {
  padding: 80px 0 275px; }
  .certification h2, .certification .voyage h3, .voyage .certification h3, .certification .chirurgies h3, .chirurgies .certification h3, .certification .innovation .overflow h3, .innovation .overflow .certification h3, .certification .employers .overflow h3:before, .employers .overflow .certification h3:before {
    width: 66.67%;
    margin: 0 auto 30px; }
  .certification img {
    display: block;
    margin: 35px auto 0; }

.voyage {
  padding: 170px 0 200px;
  background: url("../images/banners/pays-fait-bon-vivre.jpg") no-repeat center;
  background-size: cover;
  margin-bottom: 290px; }
  .voyage .center {
    margin: 0 auto; }
  .voyage h3 {
    margin-bottom: 10px; }
  .voyage h3,
  .voyage p {
    color: #fff; }
  .voyage p {
    font-weight: 700; }

/** CHIRURGIE ESTHETIQUE **/
.chirurgien {
  padding: 70px 0 300px; }

.chirurgies_title {
  padding-top: 140px; }
  .chirurgies_title h2, .chirurgies_title .voyage h3, .voyage .chirurgies_title h3, .chirurgies_title .chirurgies h3, .chirurgies .chirurgies_title h3, .chirurgies_title .innovation .overflow h3, .innovation .overflow .chirurgies_title h3, .chirurgies_title .employers .overflow h3:before, .employers .overflow .chirurgies_title h3:before {
    text-align: center;
    margin-bottom: 0; }

.chirurgies h3 {
  text-align: left;
  margin-bottom: 10px; }
  .chirurgies h3.clearCol {
    margin-top: 25px; }

.chirurgies li {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.2;
  padding: 4px 0; }

.banner_cosmetic {
  padding: 215px 0;
  background: url("../images/banners/approche-meticulause.jpg") no-repeat center;
  background-size: cover; }
  .banner_cosmetic p {
    font-weight: 700;
    color: #fff;
    width: 66.67%;
    margin-bottom: 0; }

/** INNOVATIONS **/
.innov_descr {
  padding: 60px 0 315px; }

/** TEMOIGNAGES **/
.testimonials_list {
  padding: 110px 0 60px; }
  .testimonials_list .box {
    margin-bottom: 60px; }
    .testimonials_list .box.odd {
      padding-right: 30px; }
    .testimonials_list .box.even {
      padding-left: 30px;
      float: right; }
  .testimonials_list .box_content {
    background-color: #f2f3f2;
    padding: 50px 40px; }
    .testimonials_list .box_content img {
      display: block;
      margin: 0 auto 30px; }

/** EMPLOYEURS **/
.employers {
  counter-reset: my-counter; }
  .employers .employers_descr {
    padding: 70px 0 300px; }
  .employers .before_ban {
    padding-bottom: 0;
    margin-bottom: -30px; }
  .employers .etapes_title {
    margin: 100px 0 275px; }
  .employers .overflow h3 {
    font-size: 1.125rem;
    font-weight: 300;
    margin-bottom: 15px; }
    .employers .overflow h3:before {
      content: counter(my-counter, decimal-leading-zero);
      counter-increment: my-counter;
      display: block;
      margin-bottom: 10px; }
    .employers .overflow h3.clearCol {
      margin-top: 30px; }
  .employers .overflow:last-of-type {
    margin-bottom: -90px; }

.banner_employers {
  padding: 100px 0 150px;
  background: url("../images/banners/mention-d-honneur.png") no-repeat center;
  background-size: cover; }
  .banner_employers i {
    display: block;
    color: #eccc5b;
    font-size: 100px;
    margin-bottom: 20px; }
  .banner_employers h2, .banner_employers .voyage h3, .voyage .banner_employers h3, .banner_employers .chirurgies h3, .chirurgies .banner_employers h3, .banner_employers .innovation .overflow h3, .innovation .overflow .banner_employers h3, .banner_employers .employers .overflow h3:before, .employers .overflow .banner_employers h3:before {
    margin-bottom: 15px; }
  .banner_employers p {
    font-weight: 700;
    width: 66.67%; }

/** PRENDRE UN RENDEZ-VOUS **/
.contact-map {
  margin-top: 20px; }
  .contact-map .content {
    position: relative; }
  .contact-map .map {
    height: 500px;
    width: 100%; }
  .contact-map .adresse {
    position: absolute;
    background-color: #444;
    padding: 40px 25px;
    bottom: 50px; }
    .contact-map .adresse p {
      color: #fff;
      font-size: 1.250rem;
      font-weight: bold; }
      .contact-map .adresse p .warning {
        color: #f98407; }
        .contact-map .adresse p .warning > em {
          text-decoration: underline; }
      .contact-map .adresse p .alt-addr {
        font-size: 0.8em; }

.form {
  padding: 60px 0 100px;
  background-color: #fff; }
  .form form {
    padding-top: 40px; }
  .form .col.odd {
    padding-left: 0;
    padding-right: 10px; }
  .form .col.even {
    padding-left: 10px;
    padding-right: 0; }
  .form .birthday .col {
    padding: 0 7px; }
    .form .birthday .col.first {
      padding-left: 0;
      padding-right: 14px; }
    .form .birthday .col.last {
      padding-left: 14px;
      padding-right: 0; }
  .form .birthday,
  .form .gender {
    margin-top: 30px; }
  .form .full_border {
    border-bottom: 1px solid #bbb;
    margin-bottom: 70px; }
    .form .full_border.last {
      margin-bottom: 40px; }
    .form .full_border md-input-container {
      margin: 10px 0; }
    .form .full_border label {
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      transform: none !important; }
    .form .full_border md-radio-group md-radio-button:not(:first-child) {
      margin-top: 0; }
  .form .reference {
    width: auto; }
  .form .ramq {
    padding-top: 90px; }
    .form .ramq p {
      font-size: 1rem; }
    .form .ramq .heading {
      text-transform: uppercase;
      color: #000;
      font-size: 1.250rem;
      font-weight: 400; }
  .form .submit_form {
    padding-top: 70px; }
    .form .submit_form a {
      padding: 19px 20px; }

.form_origin ul {
  width: 50%;
  margin: 0 auto; }

.form_origin li {
  float: left;
  width: 49%;
  height: 60px;
  padding: 15px;
  margin: 0 1px;
  background-color: #bbb;
  cursor: pointer;
  outline: none;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s; }
  .form_origin li:hover, .form_origin li.current {
    background-color: #1972c5; }
  .form_origin li span {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.938rem;
    font-weight: 700;
    line-height: 1.3;
    color: #fff;
    display: block; }

.success {
  padding: 80px 0 120px; }
  .success h2, .success .voyage h3, .voyage .success h3, .success .chirurgies h3, .chirurgies .success h3, .success .innovation .overflow h3, .innovation .overflow .success h3, .success .employers .overflow h3:before, .employers .overflow .success h3:before {
    font-family: "Lato", sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    color: #338bea;
    text-transform: uppercase; }
  .success .btn {
    margin-top: 60px; }

/** SITEMAP **/
.sitemap section {
  padding-bottom: 100px; }
  .sitemap section li {
    padding: 7px 0; }
  .sitemap section span {
    display: block;
    margin-bottom: 5px; }
  .sitemap section ul ul li {
    padding: 6px 0; }
  .sitemap section ul ul ul li {
    padding: 4px 0; }
    .sitemap section ul ul ul li:first-of-type {
      padding-top: 6px; }

/** ERREUR 404 **/
.error404 section {
  padding-bottom: 100px; }
  .error404 section h2, .error404 section .voyage h3, .voyage .error404 section h3, .error404 section .chirurgies h3, .chirurgies .error404 section h3, .error404 section .innovation .overflow h3, .innovation .overflow .error404 section h3, .error404 section .employers .overflow h3:before, .employers .overflow .error404 section h3:before {
    text-align: left;
    font-family: "Lato", sans-serif;
    font-size: 1.250em;
    color: #555;
    text-transform: none;
    font-weight: 400; }

/* Responsive */
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  /* GENERAL */
  .banner_top .img_container {
    overflow: hidden; }
    .banner_top .img_container img {
      height: 300px;
      width: auto;
      float: right;
      margin-right: -60px; }
  .fr .banner_top .banner_content.verylongFR {
    top: -80px; }
    .fr .banner_top .banner_content.verylongFR h1 {
      width: 70%; }
  /* HEADER */
  .main_nav li {
    padding: 30px 10px; }
  /* NAV */
  .wrist-hand .nav_surgery_type .nav_title,
  .ankle-foot .nav_surgery_type .nav_title {
    height: 132px; }
    .wrist-hand .nav_surgery_type .nav_title:after,
    .ankle-foot .nav_surgery_type .nav_title:after {
      border-width: 66px 0 66px 20px; }
  /* FOOTER */
  .footer_top .grid-5 {
    width: 45%; }
  .footer_top .grid-2 {
    width: 10%; } }

@media only screen and (max-width: 1100px) {
  .footer_top .grid-5 {
    width: 50%; }
  .footer_top .grid-2 {
    display: none; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  /* DEFAULT */
  h1 {
    font-size: 2.8em;
    margin-bottom: 20px; }
  /* GENERAL */
  .banner_top h1 {
    width: 76%; }
  .banner_top h2, .banner_top .voyage h3, .voyage .banner_top h3, .banner_top .chirurgies h3, .chirurgies .banner_top h3, .banner_top .innovation .overflow h3, .innovation .overflow .banner_top h3, .banner_top .employers .overflow h3:before, .employers .overflow .banner_top h3:before {
    width: 50%;
    font-size: 1.125rem; }
    .contact .banner_top h2, .contact .banner_top .voyage h3, .voyage .contact .banner_top h3, .contact .banner_top .chirurgies h3, .chirurgies .contact .banner_top h3, .contact .banner_top .innovation .overflow h3, .innovation .overflow .contact .banner_top h3, .contact .banner_top .employers .overflow h3:before, .employers .overflow .contact .banner_top h3:before {
      width: 41.67%; }
  .banner_top .banner_content {
    top: -23px; }
    .banner_top .banner_content.long {
      top: -72px; }
  .banner_top .img_container img {
    height: 250px;
    margin-right: -15%; }
  .banner_top.small h1 {
    width: 85%; }
  .team .banner_top.small h2, .team .banner_top.small .voyage h3, .voyage .team .banner_top.small h3, .team .banner_top.small .chirurgies h3, .chirurgies .team .banner_top.small h3, .team .banner_top.small .innovation .overflow h3, .innovation .overflow .team .banner_top.small h3, .team .banner_top.small .employers .overflow h3:before, .employers .overflow .team .banner_top.small h3:before {
    width: 40%; }
  .fr .banner_top .banner_content.longFR {
    top: -72px; }
  .banner_color {
    padding: 70px 0; }
  /* HEADER */
  header.dropdown {
    height: 270px; }
  .orthopedics header {
    margin-bottom: 60px; }
  .menu_btn {
    display: block; }
  .top_nav ul {
    display: none; }
  .main_nav li.desktop {
    display: none; }
  .dropdown .main_nav .submenu {
    height: 170px; }
  /* NAV */
  .nav_surgery_type .nav_title {
    height: 132px;
    width: 20%; }
    .wrist-hand .nav_surgery_type .nav_title,
    .ankle-foot .nav_surgery_type .nav_title {
      height: 150px; }
    .surgery_type .nav_surgery_type .nav_title {
      height: 60px; }
    .surgery_type.wrist-hand .nav_surgery_type .nav_title,
    .surgery_type.ankle-foot .nav_surgery_type .nav_title {
      height: 70px; }
    .nav_surgery_type .nav_title:after {
      border-width: 66px 0 66px 20px; }
      .wrist-hand .nav_surgery_type .nav_title:after,
      .ankle-foot .nav_surgery_type .nav_title:after {
        border-width: 75px 0 75px 20px; }
      .surgery_type .nav_surgery_type .nav_title:after {
        border-width: 30px 0 30px 15px;
        right: -15px; }
      .surgery_type.wrist-hand .nav_surgery_type .nav_title:after,
      .surgery_type.ankle-foot .nav_surgery_type .nav_title:after {
        border-width: 35px 0 35px 15px; }
  .nav_surgery_type p {
    font-size: 1.5rem;
    line-height: 1.1; }
    .surgery_type .nav_surgery_type p {
      width: 100%;
      text-align: center; }
    .surgery_type.wrist-hand .nav_surgery_type p,
    .surgery_type.ankle-foot .nav_surgery_type p {
      text-align: left; }
  .nav_surgery_type li {
    padding: 5px 10px; }
    .nav_surgery_type li a {
      font-size: 0.938rem; }
  .nav_surgery_type .grid-10 {
    width: 80%; }
    .surgery_type .nav_surgery_type .grid-10 ul {
      display: none; }
  .nav_surgery_type ul {
    padding-left: 15px; }
  .nav_surgery_type.opened .nav_title {
    height: 132px; }
    .nav_surgery_type.opened .nav_title:after {
      border-width: 66px 0 66px 20px;
      right: -20px; }
  .nav_surgery_type.opened .grid-10 ul {
    display: block; }
  .nav_surgery_type.opened a.see_all {
    display: none; }
  .surgery_type.wrist-hand .nav_surgery_type.opened .nav_title,
  .surgery_type.ankle-foot .nav_surgery_type.opened .nav_title {
    height: 150px; }
    .surgery_type.wrist-hand .nav_surgery_type.opened .nav_title:after,
    .surgery_type.ankle-foot .nav_surgery_type.opened .nav_title:after {
      border-width: 75px 0 75px 20px;
      right: -20px; }
  .surgery_type a.see_all {
    display: block; }
  /* CONTENT */
  .reputation .vcenter,
  .mention_honneur .vcenter,
  .mot_direction .vcenter,
  .visite .vcenter,
  .engagement .vcenter,
  .a_propos .vcenter,
  .faq .vcenter {
    display: block; }
  .reputation .grid-6,
  .mention_honneur .grid-6,
  .mot_direction .grid-6,
  .visite .grid-6,
  .engagement .grid-6,
  .a_propos .grid-6,
  .faq .grid-6 {
    width: 100%; }
  .reputation .col,
  .mention_honneur .col,
  .mot_direction .col,
  .visite .col,
  .engagement .col,
  .a_propos .col,
  .faq .col {
    padding-bottom: 40px; }
    .reputation .col:last-of-type,
    .mention_honneur .col:last-of-type,
    .mot_direction .col:last-of-type,
    .visite .col:last-of-type,
    .engagement .col:last-of-type,
    .a_propos .col:last-of-type,
    .faq .col:last-of-type {
      padding-bottom: 0; }
  .employeurs {
    height: 500px; }
  .temoignages {
    height: 660px; }
    .temoignages .grid-6 {
      width: 70%; }
    .temoignages .slide {
      background-position: 66%; }
      .temoignages .slide:first-of-type {
        background-position: 60%; }
  .implication {
    background-position: 80% 0; }
    .implication h2, .implication .voyage h3, .voyage .implication h3, .implication .chirurgies h3, .chirurgies .implication h3, .implication .innovation .overflow h3, .innovation .overflow .implication h3, .implication .employers .overflow h3:before, .employers .overflow .implication h3:before {
      text-align: center; }
    .implication .grid-6 {
      width: 70%; }
  .specialites .col {
    width: 100%;
    padding: 0 !important; }
  .specialites .grid-5 {
    margin-top: 30px; }
  .overflow .reverse {
    display: block; }
    .overflow .reverse .grid-5 {
      margin-right: 0; }
  .overflow .col {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important; }
    .overflow .col img {
      width: 100%; }
  .testimonials_list .box {
    width: 100%;
    padding: 0 !important; }
  .form_origin ul {
    width: 70%; }
  /* FOOTER */
  .footer_top {
    padding: 40px 0 20px; }
    .footer_top .grid-5 {
      width: 100%;
      text-align: left; }
      .footer_top .grid-5:first-of-type {
        padding-bottom: 20px; }
    .footer_top .right .title:after {
      right: auto; }
  .footer_bottom {
    padding-bottom: 30px; } }

@media only screen and (max-width: 840px) {
  .banner_top .img_container_big {
    overflow: hidden; }
    .banner_top .img_container_big img {
      height: 320px;
      width: auto; } }

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  /* DEFAULT */
  h1 {
    font-size: 2.5em; }
  h2, .voyage h3, .chirurgies h3, .innovation .overflow h3, .employers .overflow h3:before {
    font-size: 2em; }
    h2.title, .voyage h3.title, .chirurgies h3.title, .innovation .overflow h3.title, .employers .overflow h3.title:before {
      font-size: 1.3rem; }
  .grid-6 {
    width: 100%; }
  .main .col:nth-of-type(odd) {
    padding-right: 0; }
  .main .col:nth-of-type(even) {
    padding-left: 0; }
  .banner_top h1,
  .banner_top h2,
  .banner_top .voyage h3, .voyage
  .banner_top h3,
  .banner_top .chirurgies h3, .chirurgies
  .banner_top h3,
  .banner_top .innovation .overflow h3, .innovation .overflow
  .banner_top h3,
  .banner_top .employers .overflow h3:before, .employers .overflow
  .banner_top h3:before {
    width: 100% !important;
    text-align: center; }
  .banner_top h1 {
    word-wrap: break-word; }
    .banner_top h1:after {
      margin: 20px auto 0; }
  .banner_top .author,
  .banner_top .author_job {
    text-align: center; }
  .banner_top .banner_content {
    position: relative;
    top: auto !important; }
  .surgery_type .banner_top {
    margin-top: 40px; }
  .cosmetic .banner_top h1 {
    width: 100%; }
  .contact .banner_top {
    margin-top: 0; }
    .contact .banner_top h2, .contact .banner_top .voyage h3, .voyage .contact .banner_top h3, .contact .banner_top .chirurgies h3, .chirurgies .contact .banner_top h3, .contact .banner_top .innovation .overflow h3, .innovation .overflow .contact .banner_top h3, .contact .banner_top .employers .overflow h3:before, .employers .overflow .contact .banner_top h3:before {
      width: 100%; }
    .contact .banner_top p {
      text-align: center; }
  .banner_top .img_container img,
  .banner_top .img_container_big img {
    height: auto;
    width: 100%;
    float: none;
    margin-right: 0; }
  .sides {
    padding: 0; }
  .intro {
    width: 100%; }
  /* HEADER */
  header {
    height: auto;
    padding-bottom: 20px;
    margin-bottom: 50px; }
    header .grid-2 {
      width: 25%; }
    header .top_nav {
      width: 75%; }
  .main_nav {
    display: none; }
  .mobile_nav li.tablet {
    display: block; }
  /* NAV */
  .nav_slider {
    padding: 0 10px; }
    .nav_slider .prev i,
    .nav_slider .next i {
      font-size: 50px; }
  .surgery_type .nav_surgery_type .vcenter {
    display: block; }
  .nav_surgery_type .nav_title {
    display: none; }
    .surgery_type .nav_surgery_type .nav_title {
      display: block;
      width: calc(100% + 20px);
      left: -10px;
      height: 45px !important; }
      .surgery_type .nav_surgery_type .nav_title:before, .surgery_type .nav_surgery_type .nav_title:after {
        display: none; }
  .nav_surgery_type p {
    text-align: center !important; }
  .nav_surgery_type .grid-10 {
    width: 100%;
    text-align: center;
    padding: 13px 0; }
    .nav_surgery_type .grid-10 ul {
      display: none; }
  .nav_surgery_type ul {
    margin-bottom: 20px;
    padding-left: 0; }
  .nav_surgery_type li {
    display: block;
    padding: 6px 10px; }
  .nav_surgery_type.opened a.see_all {
    display: block;
    margin-bottom: 20px; }
  a.see_all {
    display: block;
    padding-left: 0; }
  /* CONTENT */
  .mention_honneur img {
    width: 200px; }
  .temoignages {
    height: auto; }
    .temoignages .grid-6 {
      width: 100%; }
    .temoignages .slide {
      padding: 50px 0;
      position: relative;
      background-image: none !important;
      background-color: #fff;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none; }
    .temoignages q {
      padding: 0 30px; }
  .employeurs {
    background-image: none;
    background-color: #f2f3f2; }
  .implication {
    background-image: none;
    background-color: #f2f3f2; }
    .implication .grid-6 {
      width: 100%; }
  .conseils li {
    width: 100%;
    display: block; }
  .list_team li p {
    padding-right: 0; }
  .surgery_type_descr img {
    display: block;
    margin: 0 auto 40px;
    padding: 0; }
  .downloads li {
    width: 100%;
    height: 100px;
    float: none; }
  .form .grid-5,
  .form .grid-7 {
    width: 100%;
    padding: 0 !important; }
  .form .full_border {
    margin-bottom: 50px; }
    .form .full_border label {
      margin-bottom: 15px; }
  .voyage {
    padding: 100px 0; }
  .chirurgies_title {
    padding-top: 70px; }
  .banner_cosmetic {
    padding: 100px 0; }
    .banner_cosmetic p {
      width: 100%; }
  .employers .before_ban {
    margin-bottom: -70px; }
  .banner_employers p {
    width: 100%; }
  .form_origin ul {
    width: 80%; }
  .form_origin li {
    height: 70px; }
  .contact-map .map {
    height: 300px; }
  .contact-map .adresse {
    position: relative;
    width: calc(100% + 20px);
    padding: 30px 25px 15px;
    left: -10px;
    right: -10px;
    bottom: auto; }
  /* FOOTER */
  .footer_top li {
    display: block;
    padding: 7px 0; }
    .en .footer_top li {
      padding: 7px 0; }
  .footer_top .left .title {
    margin-bottom: 10px; }
  .footer_top .right .title {
    margin-bottom: 33px; }
  .footer_bottom .col {
    width: 100%;
    text-align: center;
    margin-bottom: 7px; }
  .footer_bottom p {
    margin-bottom: 0; } }

@media only screen and (max-width: 620px) {
  /* HEADER */
  .top_nav .rdv_btn {
    display: none; }
  .mobile_nav .rdv_btn {
    display: block; }
  .menu_btn {
    margin-top: 35px; }
  .logo img {
    margin-top: 8px; }
  header .grid-2 {
    width: 140px; }
  header .top_nav {
    width: auto; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  /* DEFAULT */
  h1 {
    font-size: 2em; }
  h2, .voyage h3, .chirurgies h3, .innovation .overflow h3, .employers .overflow h3:before {
    font-size: 1.5em; }
  /* NAV */
  .btn.big {
    width: 100%; }
  /* CONTENT */
  .mention_honneur img {
    width: 140px; }
  .list_team {
    padding-bottom: 80px; }
    .list_team ul {
      margin-top: 75px; }
    .list_team li {
      margin: 70px 0; }
      .list_team li h2, .list_team li .voyage h3, .voyage .list_team li h3, .list_team li .chirurgies h3, .chirurgies .list_team li h3, .list_team li .innovation .overflow h3, .innovation .overflow .list_team li h3, .list_team li .employers .overflow h3:before, .employers .overflow .list_team li h3:before {
        font-size: 1.7rem;
        line-height: 1.1; }
      .list_team li .profile_content {
        top: auto;
        bottom: 82%;
        left: 10px; }
      .list_team li p.long {
        padding-right: 0; }
    .list_team .center_team {
      margin-top: 0; }
      .list_team .center_team li {
        margin-top: 120px; }
  .testimonials_list .box_content {
    padding: 40px 30px; }
  .form_origin ul {
    width: 100%; }
  .form_origin li {
    width: 100%;
    height: 60px;
    margin: 1px 0; } }

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  /* CONTENT */
  .overflow {
    padding-bottom: 40px; }
    .overflow .content {
      top: -110px; }
    .overflow:last-of-type, .overflow.last {
      margin-bottom: -70px; }
  .symptomes .intro {
    padding-bottom: 200px; }
  .symptomes .references {
    padding-top: 50px; }
  .chirurgien,
  .innov_descr,
  .employers .employers_descr {
    padding-bottom: 170px; }
  .employers .etapes_title {
    margin-bottom: 170px; } }
