// ------ auto-complete - disable default yellow color ------->
input::-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

button,
input,
select,
textarea {
	font-family: $font;
	outline: none;
}

input,
select,
textarea {
	font-weight: 600;
	color: #000;
	background: none;
	font-size: 1.125rem;
}

label {
	color: $grey_light;
	font-weight: 600;
	display: block;
	font-size: 1.125rem;
	position: static !important;

	&.required:after {
		content: '*';
	}

	.birthday &,
	.gender &,
	.full_border & {
		color: #000;
	}
}

input,
textarea {
	border: 0;
	border-bottom: 1px solid $border;
}

input[type="submit"] {
	padding: 17px !important;
	@include transition();
}

legend {
	font-size: 1.250rem;
	color: #000;
	text-transform: uppercase;
	clear: both;
	padding: 50px 0;
}

.error {
	color: $error;
	font-size: 0.875rem;
	font-weight: 400;
	padding-top: 5px;
	clear: both;
}


/* MATERIAL */
md-input-container {
	width: 100%;
	padding-bottom: 2px;
	[ng-messages] {
		min-height: 0;
	}
	.md-input {
		height: 35px;
		line-height: 35px;
		border-color: $border;
		color: #000 !important;
	}
	.md-placeholder {
		color: $border;
		//opacity: 1;
	}
	label.md-placeholder {
		color: $grey_light;
	}
	&:not(.md-input-invalid).md-input-focused {
		label {
			color: $blue;
		}
		.md-input {
			border-color: $blue;
		}
	}
	.md-char-counter {
		font-style: italic;
		color: $border;
	}
}
md-input-container {
	.md-input,
	label,
	&:not(.md-input-invalid).md-input-has-value label {
		color: $grey_light;
	}
	&.md-input-focused label:not(.md-no-float),
	&.md-input-has-placeholder label:not(.md-no-float),
	&.md-input-has-value label:not(.md-no-float) {
		-webkit-transform: translate3d(0,6px,0) scale(.82);
	    transform: translate3d(0,6px,0) scale(.82);
	}
}

.md-select-value {
	min-height: 31px;
	span {
		font-weight: 700;
		font-size: 1.125rem;
		line-height: 1.5;
		max-width: 95%;
	}
	.md-select-icon:after {
		content: '\e906';
		font-family: 'icomoon';
		color: $blue;
		font-size: 9px;
		@include transform(none);
	}
}
md-select.md-default-theme .md-select-value,
md-select .md-select-value {
	border-color: $border;
	&.md-select-placeholder {
		color: $grey_light;
		font-weight: 400;
	}
}

md-select:not([disabled]):focus .md-select-value {
	border-bottom-color: $blue;
	color: #000;
}
md-select-menu md-option:focus[selected],
md-select-menu md-option[selected] {
	color: $blue;
}

md-select.md-default-theme[disabled] .md-select-value,
md-select[disabled] .md-select-value {
	border-bottom: 1px solid $border;
	background: none;
}

md-option .md-text {
	overflow: visible;
}

md-radio-button {
	display: inline-block;
	margin-bottom: 0;
	padding-right: 50px;
	.md-on {
		background-color: $blue;
	}
	&.md-checked .md-off {
		border-color: $blue;
	}
	span {
		font-weight: 700;
		font-size: 1.125rem;
		color: #000;
	}
}
md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
	background-color: rgba(25, 114, 197, 0.25);
}
md-radio-group[disabled],
md-radio-button[disabled] {
	cursor: default;
	.md-container {
		.md-off,
		.md-on {
			cursor: default;
		}
	}
}

md-autocomplete {
	background: none;
	&[md-floating-label] md-input-container {
		padding-bottom: 0;
	}
}
.md-whiteframe-1dp,
.md-whiteframe-z1 {
	box-shadow: none;
}