.content {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px;
	@extend .cf;
}

.col {
	float: left;

	.main &:nth-of-type(odd) {
		padding-right: 10px;
	}
	.main &:nth-of-type(even) {
		padding-left: 10px;
	}

	img {
		max-width: 100%;
	}
}

.right {
	text-align: right;
}
.center {
	text-align: center;
}

.vcenter {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
}

.fright {
	float: right;
}

.reverse {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.sides {
	padding: 0 60px;
	h2 {
		margin-bottom: 20px;
	}
}

.clearCol {
	clear: both;
}

.grey {
	background-color: $grey_bg2;
}

.title {
	font-size: 1.250em;
	color: #000;
	font-weight: 700;
	&:after {
		content: '';
		width: 50px;
		height: 3px;
		background-color: $blue;
		display: block;
		margin-top: 20px;
	}
}
h2.title {
	font-family: $font;
	font-size: 1.563rem;
	font-weight: 400;
	&:after {
		margin: 20px auto 0;
	}
}
.subtitle {
	font-weight: 700;
	text-transform: uppercase;
	font-size: 0.938em;
}

.intro {
	width: 83.33%;
	text-align: center;
	margin: 0 auto 20px;
	font-size: 1.125em;
	font-weight: 300;
}

.author {
	color: #000;
	font-weight: 700;
	font-style: normal;
	text-transform: uppercase;
	font-size: 1rem;
	margin-bottom: 10px;
}
.author_job {
	font-size: 0.875rem;
	font-weight: 300;
}

.video_container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    iframe {
	    position: absolute;
	    top:0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
}

.disc {
	margin-left: 20px;
	li {
		list-style-type: disc;
		font-size: 1.125rem;
		font-weight: 300;
		line-height: 1.2;
		padding: 4px 0;
	}
}